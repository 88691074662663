const eSounds = {
  EST_BACKGROUND: { soundName: 'SFX', volume: 0.7, group: 'music' },
  EST_REEL_ANTISIPATOR: { soundName: 'antisipatorStart', volume: 0.8, group: 'default' },
  EST_REEL_ANTISIPATOR_TILE: { soundName: 'antisipatorEnd', volume: 0.6, group: 'default' },
  EST_BIG_WINS_BACKGROUND: { soundName: 'bigWinBG', volume: 0.6, group: 'winning' },
  EST_WIN: { soundName: 'win', volume: 0.8, group: 'winning' },
  EST_BIG_WIN: { soundName: 'bigWin', volume: 0.7, group: 'winning' },
  EST_MEGA_WIN: { soundName: 'megaWin', volume: 0.7, group: 'winning' },
  EST_EPIC_WIN: { soundName: 'epicWin', volume: 0.7, group: 'winning' },
  EST_BUTTON_CLICK: { soundName: 'buttonClick', volume: 1, group: 'default' },
  EST_REEL_STOP: { soundName: 'reelStop', volume: 1, group: 'default' },
  EST_SCATTER_STOP: { soundName: 'scatterStop', volume: 0.6, group: 'default' },
  EST_FS_TOTAL_WIN: { soundName: 'freeSpinsEnd', volume: 0.6, group: 'winning' },
  EST_START_FREESPINS: { soundName: 'freeSpinsStart', volume: 0.6, group: 'winning' },
  EST_DOGS_RUN: { soundName: 'dogsRun', volume: 0.7, group: 'default' },
  EST_WILD_STOP: { soundName: 'wildStop', volume: 1, group: 'default' },
  EST_WILD_SWAP: { soundName: 'symbolSwap', volume: 0.8, group: 'default' }
};

const soundsPreloaderConfig = Object.fromEntries(Object.keys(eSounds).map(key => [key, eSounds[key].soundName]));

export {
  eSounds,
  soundsPreloaderConfig,
}
