import { gsap } from "gsap";

export default class ControllerWinCombination {
  constructor({ reelsContainer, controllerReels }) {
    this.reelsContainer = reelsContainer;
    this.ControllerReels = controllerReels;

    this.reelsMap = new Map();

    this._overlayAlpha = 0.8;
    this._overlayColor = '0x000639';

    this.init();
  }

  init() {
    this._createContainer();
    this._createOverlay();
  }

  showCombination(data) {
    this._showOverlay();
    this._bringSymbolsBack();

    data.params.path.forEach((pathData, index) => {
      if (pathData >= 0) {
        const symbol = this.ControllerReels.reels[index].symbols[pathData];
        this._bringSymbolToFront(symbol);
        symbol.startAnimation();
      }
    });
  }

  end() {
    this._bringSymbolsBack();
    this._hideOverlay();
  }

  _bringSymbolToFront(symbol) {
    if (symbol.parent === this.container) return;

    if (!this.reelsMap.has(symbol.parent)) {
      this.reelsMap.set(symbol.parent, []);
    }

    this.reelsMap.get(symbol.parent).push(symbol);

    const animationSymbolPosition = this.container.toLocal({ x: 0, y: 0 }, symbol);
    symbol.x = animationSymbolPosition.x;
    symbol.y = animationSymbolPosition.y;
    this.container.addChild(symbol);
  }

  _bringSymbolsBack() {
    for (let [reel, symbols] of this.reelsMap) {
      symbols.forEach(symbol => {
        const animationSymbolPosition = reel.toLocal({ x: 0, y: 0 }, symbol);
        symbol.x = animationSymbolPosition.x;
        symbol.y = animationSymbolPosition.y;
        reel.addChild(symbol);
      });
    }

    this.reelsMap.clear();
  }

  _createContainer() {
    this.container = new PIXI.Container();
    this.reelsContainer.addChild(this.container);
  }

  _createOverlay() {
    this.overlay = new PIXI.Graphics();
    this.overlay.beginFill(this._overlayColor, this._overlayAlpha);
    this.overlay.drawRect(0, 0, this.reelsContainer.width, this.reelsContainer.height);
    this.overlay.endFill();

    this.overlay.visible = false;
    this.container.addChild(this.overlay);
  }

  _showOverlay() {
    if (this.overlay.visible) return;

    this.overlay.visible = true;
    gsap.fromTo(this.overlay, { alpha: 0 }, { alpha: 1, duration: 0.3 });
  }

  _hideOverlay() {
    if (!this.overlay.visible) return;

    gsap.fromTo(this.overlay, { alpha: 1 }, {
      alpha: 0, duration: 0.3, onComplete: () => {
        this.overlay.visible = false
      }
    });
  }
}
