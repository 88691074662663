const uiConfig = {
  H: {
    offsets: {
      right_panel: { top: 0, left: -11 },
      tournaments_panel: { top: 0, left: -1430 }
    },
  },
  V: {
    offsets: {
      top: -100,
      left: 0,
    },
  }
};

export {
  uiConfig,
}
