import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../../enums/eEventTypes';
import ControllerFreeSpinsGame from './ControllerFreeSpinsGame';
import EntryPoint from 'Engine/EntryPoint';
import ControllerWildDogs from './wildDogs/ControllerWildDogs';
import ControllerWinCombination from './ControllerWinCombination';
import eStateTypes from '../../../enums/eStateTypes';

export default class ControllerFeatures {
  constructor({ reelsContainer, controllerReels, controllerReelsBackground }) {
    this.reelsContainer = reelsContainer;
    this.ControllerReels = controllerReels;
    this.ControllerReelsBackground = controllerReelsBackground;

    this.init();
    this.addListeners();

    if (EntryPoint.GameModel.isFreeSpinsMode) this.startFreeSpins();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_STATE_MANAGER__STATE_CHANGED, this.onStateChange, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_START__SHOWN_UP, this.startFreeSpins, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_END__SHOWN_UP, this.endFreeSpins, this);
    GlobalDispatcher.add(eEventTypes.EET_FEATURES_STATE__DOGS_RUN__START, this.runDogs, this);
    GlobalDispatcher.add(eEventTypes.EET_FEATURES_STATE__SYMBOLS_SWAP__START, this.swapSymbols, this);
    GlobalDispatcher.add(eEventTypes.EET_WINNINGS_CONTROLLER__SLOW_WIN, this.showWin, this);
  }

  startFreeSpins() {
    this.ControllerFreeSpinsGame.start();
    this.ControllerReelsBackground.hideDividers([1, 2]);
  }

  endFreeSpins() {
    this.ControllerFreeSpinsGame.stop();
    this.ControllerReelsBackground.showDividers([1, 2]);
  }

  runDogs() {
    this.ControllerWildDogs.letTheDogsOut(EntryPoint.GameModel.wildsData);
  }

  swapSymbols() {
    this.ControllerWildDogs.swapSymbols();
  }

  showWin(data) {
    this.ControllerReelsBackground.playWinAnimation();
    this.ControllerWinCombination.showCombination(data);
  }

  onStateChange({ params: stateName }) {
    if (stateName === eStateTypes.EST_REQUEST_ROLLING ||
      stateName === eStateTypes.EST_FREE_SPINS_IN ||
      stateName === eStateTypes.EST_FREE_SPINS_OUT) this.ControllerWinCombination.end();
  }

  init() {
    this.ControllerFreeSpinsGame = new ControllerFreeSpinsGame(this.ControllerReels);
    this.ControllerWinCombination = new ControllerWinCombination({
      reelsContainer: this.reelsContainer,
      controllerReels: this.ControllerReels,
    });
    this.ControllerWildDogs = new ControllerWildDogs({
      reelsContainer: this.reelsContainer,
      controllerReels: this.ControllerReels
    });
  }
}
