export default {
  ESI_SCATTER: 1,
  ESI_WILD: 2,
  ESI_DOG_1: 3,
  ESI_DOG_4: 4,
  ESI_DOG_6: 5,
  ESI_DOG_8: 6,
  ESI_A: 7,
  ESI_K: 8,
  ESI_Q: 9,
  ESI_J: 10
}
