import BaseGameButton from '../ui/BaseGameButton';

export default class ButtonAnimated extends BaseGameButton {
  constructor(data, displayData) {
    super(data, displayData);
  }

  init() {
    super.init();

    this.animationContainer = this.getChildByName('animationContainer');
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 0.01);
    mask.drawRect(0, 0, this.width, this.height);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }

  set animation(animation) {
    this._animation = animation;
    this.animationContainer.addChild(this._animation);
  }
}
