import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../../../enums/eAnimationTypes';
import { getRandom } from 'Engine/utils/getRandom';

export default class Dog extends PIXI.Container {
  constructor() {
    super();

    this.visible = false;
    this.animation = null;
    this._finished = true;
    this._animationName = null;

    this._velocity = 0;
    this._minVelocity = 30;
    this._maxVelocity = 60;

    this.dustAnimations = [];
    this.dustStack = [];
    this._dustGap = 300;

    this.init();
  }

  init() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_DOGS_RUN);
    this.animation.visible = false;
    this.addChild(this.animation);
  }

  run() {
    this._finished = false;
    this._setAnimationToInitialPosition();
    this.velocity = getRandom(this._minVelocity, this._maxVelocity);
    this.dustStack = [...this.dustAnimations];

    this.animation.state.setAnimation(0, this._animationName, true);
    this.visible = true;
    this.animation.visible = true;

    requestAnimationFrame(this._update);
  }

  _update = () => {
    this.animation.x += this._velocity;

    if (this.dustStack.length && this.animation.x >= this.dustStack[this.dustStack.length - 1].x) {
      const dust = this.dustStack.pop();
      dust.state.setAnimation(0, 'dust', false);
      dust.visible = true;
    }

    if (this.animation.x >= this._trackDistance) {
      this._finished = true;
      this._reset();
      return;
    }

    requestAnimationFrame(this._update);
  };

  _setAnimationToInitialPosition() {
    this.animation.x = 0;
    this.animation.y = 0;
  }

  _reset() {
    this.dustAnimations.forEach(animation => animation.visible = false);
    this.animation.visible = false;
    this.visible = false;
  }

  _initDustAnimations() {
    this.dustAnimations = [];
    const dustAnimationsCount = Math.ceil(this._trackDistance / this._dustGap);
    for (let i = dustAnimationsCount; i > 0; i--) {
      const dust = animationCreator.createAnimation(eAnimationTypes.EAT_DOGS_RUN);
      dust.visible = false;
      dust.x = this._dustGap * i;
      dust.y = this.animation.y + this.animation.height / 2;
      this.dustAnimations.push(dust);
      this.addChild(dust);
    }
  }

  set animationName(value) {
    this._animationName = value;
  }

  set velocity(value) {
    this._velocity = value;
    this.animation.state.timeScale = this._velocity / ((this._minVelocity + this._maxVelocity) / 2);
  }

  get finished() {
    return this._finished;
  }

  get distanceCovered() {
    return this.animation.x - this.animation.width / 3;
  }

  get width() {
    return this.animation.width;
  }

  set startPosition(pos) {
    this.x = pos.x;
    this.y = pos.y;
    this._setAnimationToInitialPosition();
  }

  set trackDistance(value) {
    this._trackDistance = value;
    this._initDustAnimations();
  }
}
