import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class WinAmountAnimated {
  constructor(element, style = {}) {

    this._decimals = 2;
    this.label = new PIXI.BitmapText('0', { ...element.style, ...style, fontName: 'BMFont' });
    this.label.anchor.set(0.5);
    // this.label.x = element.displayData.width / 2;
    this.label.y = element.displayData.height / 2;
    this.label.visible = false;
    element.removeChildren();
    element.addChild(this.label);

    this.onStop = () => {
    };
    this.onComplete = () => {
    };
  }

  animate(startValue = 0, endValue, duration) {
    this.needStop = false;
    this.label.text = startValue.toFixed(this._decimals);

    this.label.visible = true;
    this.startValue = startValue;
    this.endValue = endValue;
    this.duration = duration;
    this.startTime = Date.now();
    requestAnimationFrame(this.animateValueChanged.bind(this));
  }

  set(value) {
    this.label.text = value;
  }

  stop() {
    this.needStop = true;
  }

  _onStop() {
    this.onStop();
    this.onComplete();
  };

  set decimals(value) {
    this._decimals = value;
  }

  animateValueChanged() {
    if (this.needStop) return;

    const time = Date.now() - this.startTime;

    if (time >= this.duration) {
      // this.label.text = MoneyFormat.formatCurrency(this.endValue, this._decimals, false);
      this.label.text = this.endValue.toFixed(this._decimals);
      return this._onStop();
    }

    const progress = time / this.duration;
    const amount = (this.endValue - this.startValue) * progress + this.startValue;
    // this.label.text = amount.toFixed(this._decimals);
    this.label.text = amount.toFixed(this._decimals);

    requestAnimationFrame(this.animateValueChanged.bind(this));
  };

  set y(value) {
    value = Number(value);
    let y = this.label.parent.displayData.height / 2;
    if (value) y += value;
    this.label.y = y;
  }

  get scale() {
    return this.label.scale;
  }
}
