import * as PIXI from 'pixi.js';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { eSounds } from '../../enums/eSounds';
import FlashLib from 'flashlib_onlyplay';
import eSymbolIndex from '../symbols/eSymbolIndex';
import eEventTypes from '../../enums/eEventTypes';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';

export default class ReelsContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.init();
    this.addListeners();
    this.initReels();
  }

  init() {
    this._stopSoundPlayed = false;
    this._scatterSoundPlayed = false;
    this.hasScatter = false;
    this._scatterSymbols = [];

    this.ControllerReels = this.getChildByName('controllerReels');
    this.Antisipators = this.getChildByName('antisipators');
    this.Antisipators.maxCountForStartAnimation = 5;
    this.Antisipators.mainSoundType = eSounds.EST_REEL_ANTISIPATOR.soundName;
    this.Antisipators.mainSoundVolume = eSounds.EST_REEL_ANTISIPATOR.volume;
    this.Antisipators.mainSoundGroup = eSounds.EST_REEL_ANTISIPATOR.group;
    this.Antisipators.tileSoundType = eSounds.EST_REEL_ANTISIPATOR_TILE.soundName;
    this.Antisipators.tileSoundVolume = eSounds.EST_REEL_ANTISIPATOR_TILE.volume;
    this.Antisipators.tileSoundGroup = eSounds.EST_REEL_ANTISIPATOR_TILE.group;
    this.Antisipators.animationType = eAnimationTypes.EAT_ANTISIPATOR;
    this.Antisipators.animationName = eAnimationNames.EAN_ANTISIPATOR;
    this.Antisipators.triggerSymbolsId = eSymbolIndex.ESI_SCATTER;
    this.Antisipators.init();

    this._drawMask();
  }

  initReels() {
    this.ControllerReels.hideBottomSymbols = false;
    this.ControllerReels.init(EntryPoint.configData.reels.availableReelsNormal);
  }

  addListeners() {
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onReelStopped = this.onReelStopped.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onAllReelsStopped.bind(this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPIN_IN_STATE__START, this.onStartBonusGameInState, this);
  }

  onHitBar(reelIndex) {
    this.Antisipators.tryStopAntisipatorOnReel(reelIndex);
    this.Antisipators.tryStartAntisipatorForReel(reelIndex + 1);

    if (!EntryPoint.GameModel.freespinsEnabled) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      for (let i = this.ControllerReels.topSimbolsCount; i <= symbols.length - 1 - this.ControllerReels.bottomSimbolsCount; i++) {
        const symbol = symbols[i];
        if (symbol.id === eSymbolIndex.ESI_SCATTER) {
          this.hasScatter = true;
          this._scatterSymbols.push(symbol);
        }
      }
    }

    this.playStopSound();
  }

  playStopSound() {
    if (!this._stopSoundPlayed) {
      SoundManager.play(eSounds.EST_REEL_STOP.soundName, eSounds.EST_REEL_STOP.volume + 0.2 * Math.random(), false, eSounds.EST_REEL_STOP.group);
      this._stopSoundPlayed = EntryPoint.GameSettings.quickStop;
    }

    if (this.hasScatter) {
      this.hasScatter = false;
      if (!this._scatterSoundPlayed) {
        SoundManager.play(eSounds.EST_SCATTER_STOP.soundName, eSounds.EST_SCATTER_STOP.volume + 0.2 * Math.random(), false, eSounds.EST_SCATTER_STOP.group);
        this._scatterSoundPlayed = EntryPoint.GameSettings.quickStop;
      }
    }
  }

  onReelStopped(reelIndex) {
    const spacialSymbolIndex = EntryPoint.GameModel.winnings?.jackpotters?.length && EntryPoint.GameModel.winnings?.jackpotters[0]?.path[reelIndex];
    if (spacialSymbolIndex) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      symbols[spacialSymbolIndex].startStopAnimation();
    }
  }

  onAllReelsStopped() {
    GlobalDispatcher.dispatch(eEventTypes.EET_REELS_STOPPED);
  }

  onStartBonusGameInState() {
    if (!this.willShowScattersAnimation) return;
    this._scatterSymbols.forEach(scatterSymbol => scatterSymbol.startAnimation());
  }

  start() {
    this._stopSoundPlayed = false;
    this._scatterSoundPlayed = false;
    this._scatterSymbols = [];
    const config = this._getReelsStartConfig();
    Object.assign(this.ControllerReels, config);
    this.ControllerReels.start();
  }

  stop(data) {
    this.data = data;
    const config = this._getReelsStopConfig();
    const symbolsCountForStop = this._getSymbolsCountForStop(data, config);
    Object.assign(this.ControllerReels, config, { symbolsCountForStop });
    this.ControllerReels.stop(this.data);
  }

  _getSymbolsCountForStop(data, config) {
    let currentValues = config.hasOwnProperty('symbolsCountForStop') ? config.symbolsCountForStop : this.ControllerReels.symbolsCountForStop;
    if (!EntryPoint.GameSettings.quickStop && !EntryPoint.GameModel.isWildsFeature) this.Antisipators.checkAntisipators(data);
    if (!this.Antisipators.involvedReels.length) return currentValues;

    let antisipatorDiff = 0;
    let antisipatorDiffStep = 40;

    const newValues = [];
    for (let i = 0; i < this.ControllerReels.reels.length; i++) {
      let value = this.ControllerReels.getConfigValue(currentValues, i);
      if (this.Antisipators.involvedReels.includes(i)) {
        antisipatorDiff += antisipatorDiffStep;
      }
      newValues.push(value + antisipatorDiff);
    }

    return newValues;
  }

  _getReelsConfig() {
    let config = EntryPoint.configData.reels.config;
    return EntryPoint.GameModel.freespinsEnabled ? config.freeSpins : config.regularSpins;
  }

  _getReelsStopConfig() {
    const config = this._getReelsConfig();
    return (EntryPoint.GameSettings.quickStop || EntryPoint.GameSettings.quickSpin) ? config.quick.stop : config.default.stop;
  }

  _getReelsStartConfig() {
    const config = this._getReelsConfig();
    return EntryPoint.GameSettings.quickSpin ? config.quick.start : config.default.start;
  }

  _drawMask() {
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawPolygon(12,0, 1498,0, 1504,3, 1507,6, 1510,12, 1510,771, 1507,776, 1504,779, 1498,782, 12,782, 6,779, 3,776, 0,770, 0,12, 3,6, 6,3);
    mask.endFill();
    this.addChild(mask);
    mask.x = 0;
    mask.y = 192;
    this.mask = mask;
  }

  get willShowScattersAnimation() {
    return this._scatterSymbols.length >= 3;
  }
}
