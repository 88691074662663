import FlashLib from "flashlib_onlyplay";

import BaseCheckBox from "./controllers/ui/BaseGameCheckBox";
import Main from "./Main";
import ReelsContainer from "./controllers/reels/ReelsContainer";
import ControllerOverlay from "./controllers/background/ControllerOverlay";
import Symbol from "./controllers/symbols/Symbol";
import StartScreenContainer from './controllers/startScreen/startScreen';
import PaytableContainer from "./controllers/paytable/PaytableContainer";
import PaytableContent from "./controllers/paytable/PaytableContent";
import PaytableControls from "./controllers/paytable/PaytableControls";
import SymbolAnimation from './controllers/symbols/SymbolAnimation';
import PopupWin from './controllers/popups/popupWin/PopupWin';
import BackgroundContainer from './controllers/background/BackgroundContainer';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import ControllerReelsBackground from './controllers/reels/ReelsBackground';
import ControllerFreespinsStart from './controllers/popups/freespins/ControllerFreespinsStart';
import ControllerFreespinsEnd from './controllers/popups/freespins/ControllerFreespinsEnd';
import ControllerSlot from './controllers/slot/ControllerSlot';
import ControllerAntisipators from 'Engine/base/antisipators/ControllerAntisipators';
import ControllerBonusPurchase from './controllers/bonusPurchase/ControllerBonusPurchase';
import ControllerBonusPurchaseCard from './controllers/bonusPurchase/ControllerBonusPurchaseCard';
import ControllerConfirm from './controllers/bonusPurchase/ControllerConfirm';
import ButtonAnimated from './controllers/bonusPurchase/ButtonAnimated';
import ControllerSettings from './controllers/popups/settings/ControllerSettings';
import BetSelectorContainer from './controllers/ui/BetSelectorContainer';
import BaseGameButton from './controllers/ui/BaseGameButton';
import BaseGameUiContainer from './controllers/ui/BaseGameUiContainer';

FlashLib.registerClass('Button', BaseGameButton);
FlashLib.registerClass('CheckBox', BaseCheckBox);

FlashLib.registerClass('Main', Main);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('Symbol', Symbol);
FlashLib.registerClass('SettingsContainer', ControllerSettings);
FlashLib.registerClass('StartScreenContainer', StartScreenContainer);
FlashLib.registerClass('UiContainer', BaseGameUiContainer);
FlashLib.registerClass('BetContainer', BetSelectorContainer);
FlashLib.registerClass('OverlayContainer', ControllerOverlay);
FlashLib.registerClass('PaytableContainer', PaytableContainer);
FlashLib.registerClass('PaytableContent', PaytableContent);
FlashLib.registerClass('PaytableControls', PaytableControls);
FlashLib.registerClass('SymbolAnimation', SymbolAnimation);
FlashLib.registerClass('PopupWin', PopupWin);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('ControllerReelsBackground', ControllerReelsBackground);
FlashLib.registerClass('ControllerFreespinsStart', ControllerFreespinsStart);
FlashLib.registerClass('ControllerFreespinsEnd', ControllerFreespinsEnd);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerAntisipators', ControllerAntisipators);
FlashLib.registerClass('ControllerBonusPurchase', ControllerBonusPurchase);
FlashLib.registerClass('ControllerBonusPurchaseCard', ControllerBonusPurchaseCard);
FlashLib.registerClass('ControllerConfirm', ControllerConfirm);
FlashLib.registerClass('ButtonAnimated', ButtonAnimated);
