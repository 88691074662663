export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};
export const goldFontStyle = {
  breakWords: true,
  dropShadow: true,
  dropShadowAngle: 45,
  dropShadowBlur: 6,
  dropShadowColor: "#5b0200",
  fill: [
    "white",
    "#ffff6e",
    "#ffffa3",
    "#ffb209",
    "#9c4500",
    "#9c4500",
  ],
  fillGradientStops: [
    0.2,
    0.25,
    0.4,
    0.54,
    0.65,
    0.7,
    0.9
  ],
  lineJoin: "round",
  stroke: "#ffde05",
  strokeThickness: 2,
  fontWeight: 100,

};

export const orangeGradientWithStrokeAndShadow = {
  dropShadow: true,
  dropShadowAngle: 1.8,
  dropShadowBlur: 4,
  dropShadowColor: "#60001d",
  dropShadowDistance: 16,
  fill: [
    "#ff9000",
    "#ffe444",
    "#fffb8b",
    "#ffab00"
  ],
  fillGradientStops: [
    0.3,
    0.6,
    0.5
  ],
  letterSpacing: 5,
  lineJoin: "round",
  miterLimit: 0,
  stroke: "#faff00",
  strokeThickness: 6,
  whiteSpace: "normal"
};

export const winFontStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 16,
  dropShadowColor: "#4c0000",
  dropShadowDistance: 17,
  fill: [
    "#f94803",
    "#fb8b37",
    "yellow",
    "#ffff8e",
    "yellow",
    "#ff6e02"
  ],
  fillGradientStops: [
    0.22,
    0.3,
    0.4,
    0.55,
    0.72,
    0.83
  ],
  lineJoin: "round",
  stroke: "#4a0000",
  strokeThickness: 6
}

export const jacpotPanelsAmountFontStyles = {
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowAngle: 1.5,
  dropShadowBlur: 11,
  dropShadowColor: "#1d0300",
  dropShadowDistance: 13,
  fill: [
    "white",
    "#ffff6e",
    "#ffffa3",
    "#ffb209",
    "#9c4500",
    "#9c4500",
    "#ffb209"
  ],
  fillGradientStops: [
    0.2,
    0.25,
    0.4,
    0.54,
    0.65,
    0.7,
    0.9
  ],
  lineJoin: "round",
  stroke: "#5b0200",
  strokeThickness: 7
}

export const orangeGradientFontStyle = {
  fill: [
    "#ff7200",
    "#ffeb6b",
    "#ffae31"
  ]
}
