import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import { getRandom } from 'Engine/utils/getRandom';

export default class ControllerReelsBackground extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.logoAnimation = null;
    this.frameAnimation = null;
    this.dividers = [];

    this._logoAnimationDelaysRange = [10000, 20000];
    this._currentLogoAnimationDelay = getRandom(this._logoAnimationDelaysRange[0], this._logoAnimationDelaysRange[1]);
    this._lastLogoDelayElapsedTime = 0;

    this.init();
    requestAnimationFrame(this._loopLogoAnimation);
  }

  init() {
    for (let i = 0; i < EntryPoint.configData.reels.reelsCount - 1; i++) {
      this.dividers.push(this.getChildByName(`divider_${i}`));
    }

    this.logoAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_UI);
    this.logoAnimation.visible = false;
    this.logoAnimation.state.addListener({
      start: () => this.logoAnimation.visible = true,
      complete: () => this.logoAnimation.visible = false,
    });

    this.frameAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_UI);
    this.frameAnimation.state.setAnimation(0, eAnimationNames.EAN_FRAME__IDLE, true);

    const animationPlace = this.getChildByName('animationPlace');
    animationPlace.addChild(this.logoAnimation, this.frameAnimation);
  }

  playWinAnimation() {
    this.frameAnimation.state.setAnimation(0, eAnimationNames.EAN_FRAME__WIN, false);
    this.frameAnimation.state.addAnimation(0, eAnimationNames.EAN_FRAME__IDLE, true, 0);
  }

  showDividers(indexes) {
    indexes.forEach(index => this.dividers[index].visible = true);
  }

  hideDividers(indexes) {
    indexes.forEach(index => this.dividers[index].visible = false);
  }

  _loopLogoAnimation = (timeElapsed) => {
    let logoDelayElapsedTime = timeElapsed % this._currentLogoAnimationDelay;

    if (logoDelayElapsedTime < this._lastLogoDelayElapsedTime) {
      this.logoAnimation.state.setAnimation(0, eAnimationNames.EAN_LOGO, false);
      this._currentLogoAnimationDelay += getRandom(this._logoAnimationDelaysRange[0], this._logoAnimationDelaysRange[1]);
    }

    this._lastLogoDelayElapsedTime = logoDelayElapsedTime;
    requestAnimationFrame(this._loopLogoAnimation);
  };
}
