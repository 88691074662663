import SoundManager from 'Engine/soundManager/SoundManager';
import EventEmitter from '../../../eventEmitter/eventEmitter';
import * as PIXI from 'pixi.js';
import eAnimationTypes from '../../../../enums/eAnimationTypes';

export default class winController extends EventEmitter {
  constructor({ config, controllerPopup, animation, counter }) {
    super();

    this.ControllerPopup = controllerPopup;
    this.animation = animation;
    this.counter = counter;
    this._config = config;

    this._startTimeStamp = null;
    this._durationOnNextCall = null;
    this._counterOffsetOnNextCall = null;

    this._active = false;
    this._startValue = 0;
    this._endValue = 0;

    this.onEnd = () => {
    };
  }

  start() {
    this._active = true;
    if (this._config.animationName) {
      this.animation.visible = true;
      this.animation.state.setAnimation(0, this._config.animationName.START, false);
      this.animation.state.addAnimation(0, this._config.animationName.IDLE, true, 0);
    } else {
      if (this.animation) this.animation.visible = false;
    }

    if (this._config.sound) {
      this._playSound();
    }

    this.counter.animate(this._startValue, this._endValue, (this._durationOnNextCall ?? this._config.duration) - (this._counterOffsetOnNextCall ?? this._config.counterOffset));

    requestAnimationFrame(this._trackDuration);
  }

  setIdle() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, this._config.animationName.IDLE, true);
  }

  setDurationOnNextCall(value) {
    this._durationOnNextCall = value;
  }

  setCounterOffsetOnNextCall(value) {
    this._counterOffsetOnNextCall = value
  }

  complete() {
    this.stop();
    this.emit('complete');
  }

  stop() {
    this._active = false;
    this._startTimeStamp = null;
    this._durationOnNextCall = null;
    this._counterOffsetOnNextCall = null;
    this.onEnd();
  }

  _trackDuration = (timestamp) => {
    if (!this._active) return;

    if (!this._startTimeStamp) this._startTimeStamp = timestamp;
    if (timestamp - this._startTimeStamp >= (this._durationOnNextCall ?? this._config.duration)) {
      this.complete();
    }

    requestAnimationFrame(this._trackDuration);
  };

  _playSound() {
    return SoundManager.play(this._config.sound.soundName, this._config.sound.volume, false, this._config.sound.group);
  }

  get counterOffset() {
    return this._counterOffsetOnNextCall ?? this._config.counterOffset;
  }

  get duration() {
    return this._durationOnNextCall ?? this._config.duration;
  }

  get startValue() {
    return this._startValue;
  }

  set startValue(value) {
    this._startValue = value;
  }

  set endValue(value) {
    this._endValue = value;
  }
}
