import gameConfig from '../../assets/config';
import starTexture from '../../assets/images/purchase/star_particle.png';


import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import UbuntuMedium from '../../assets/fonts/ubuntu/UBUNTU-M.ttf';

import flashlib from '../../assets/exported/gameMobile/FlashLib.json';

import eAnimationTypes from '../enums/eAnimationTypes';

import Config from '../Config';

import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import { soundsPreloaderConfig } from '../enums/eSounds';

const scale = getAssetsScale({
  availableScales: ['0.7', '0.75', '0.8', '0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
})
const assets = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;

export default {
  assets: [
    { name: 'GameAssets', path: assets },
    { name: 'GameFlashLib', path: flashlib },
    { name: 'gameConfig', path: gameConfig },
    { name: eAnimationTypes.EAT_SYMBOLS, path: 'animations/symbols_ir_dew/ir_symbols_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE_BUTTON, path: 'animations/bonusPurchaseButton/button_bones_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_DOGS_RUN, path: 'animations/dogs_run_dew/ir_dogs_run_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_UI, path: 'animations/ui_animation_dew/ui_animation.json', extension: 'json' },
    { name: eAnimationTypes.EAT_FREE_SPINS_POPUPS, path: 'animations/free_spins_pop_up_dew/skeleton.json', extension: 'json' },
    { name: 'BMFont', path: 'fonts/BMFont/BMFont.xml', extension: 'xml' },
    { name: 'starsParticle', path: starTexture, extension: 'png' },
  ],
  delayedAssets: [
    { name: eAnimationTypes.EAT_BIG_WIN, path: 'animations/bigWins/ir_DWB.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE, path: 'animations/ir_bonus_purchase_DEW/i_r_purchase_bonuses.json', extension: 'json' },
    { name: eAnimationTypes.EAT_ANTISIPATOR, path: 'animations/antisipation_dew/antisipation_iggy_racing.json', extension: 'json' },
  ],
  fonts: [
    {
      name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuMedium',
      path: UbuntuMedium,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    }
  ],
  sounds: soundsPreloaderConfig
}
