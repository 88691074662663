import eSymbolIndex from '../controllers/symbols/eSymbolIndex';

export default {
  EAN_PURCHASE__TITLE__START: 'title_start',
  EAN_PURCHASE__TITLE__IDLE: 'title_idle',
  EAN_PURCHASE__BUY_BUTTON: 'buy_button_side',
  EAN_PURCHASE__OPEN_POPUP_BUTTON: 'animation',
  EAN_PURCHASE__CARD_FREESPINS__IDLE: 'idle_free_spins_card',
  EAN_PURCHASE__CARD_FREESPINS__ACTION: 'buy_free_spins_card',

  EAN_ANTISIPATOR: 'antisipation',
  EAN_LOGO: 'emblem_animation',
  EAN_FRAME__IDLE: 'frame_idle',
  EAN_FRAME__WIN: 'frame_win',
  EAN_FREE_SPINS_START__START: 'you_won_start',
  EAN_FREE_SPINS_START__IDLE: 'you_won_idle',
  EAN_FREE_SPINS_END__START: 'total_win_start',
  EAN_FREE_SPINS_END__IDLE: 'total_win_idle',
}

export const eSymbolsAnimationNames = {
  [eSymbolIndex.ESI_SCATTER]: 'scatter',
  [eSymbolIndex.ESI_WILD]: 'wild',
  [eSymbolIndex.ESI_DOG_1]: '1_dog',
  [eSymbolIndex.ESI_DOG_4]: '4_dog',
  [eSymbolIndex.ESI_DOG_6]: '6_dog',
  [eSymbolIndex.ESI_DOG_8]: '8_dog',
  [eSymbolIndex.ESI_A]: 'a',
  [eSymbolIndex.ESI_K]: 'k',
  [eSymbolIndex.ESI_Q]: 'q',
  [eSymbolIndex.ESI_J]: 'j',
  ESAN_WILD_APPEARANCE: 'wild_small_start',
  ESAN_WILD_SWAP: 'wild_small_change'
};

export const eBigWinAnimationNames = {
  START: 'big_win_start',
  IDLE: 'big_win_idle'
};

export const eMegaWinAnimationNames = {
  START: 'mega_win_start',
  IDLE: 'mega_win_idle'
};

export const eEpicWinAnimationNames = {
  START: 'epic_win_start',
  IDLE: 'epic_win_idle'
};
