import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../../../enums/eEventTypes';
import Dog from './dog';
import { cloneArray, shuffle } from 'Engine/utils/array';
import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import eLibraryItems from '../../../../enums/eLibraryItems';
import eSymbolIndex from '../../../symbols/eSymbolIndex';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../../../enums/eSounds';

export default class ControllerWildDogs {
  constructor({ reelsContainer, controllerReels }) {
    this.reelsContainer = reelsContainer;
    this.ControllerReels = controllerReels;

    this._dogs = [];
    this._dropPoints = null;
    this._swapPoints = [];
    this._symbolsPositionsGrid = null;
    this._wildSymbols = [];
    this._wildSymbolsCount = 5;
    this._wildsSwapPromises = [];
    this._wildsAppearancePromises = [];
    this._availableDogsAnimations = ['1', '4', '6', '8'];

    this._overlayAlpha = 0.8;
    this._overlayColor = '0x000639';

    this.init();
  }

  init() {
    this._createContainer();
    this._createOverlay();
    this._createSymbols();
    this._createDogs();
    this._createSymbolsPositionsGrid();
  }

  letTheDogsOut(data) {
    this._calculateDropPoints(data);
    this._wildSymbolsStack = [...this._wildSymbols];
    this._showOverlay();
    SoundManager.play(eSounds.EST_DOGS_RUN.soundName, eSounds.EST_DOGS_RUN.volume, false, eSounds.EST_DOGS_RUN.group);

    const animations = shuffle(cloneArray(this._availableDogsAnimations));
    this.dogs.forEach(dog => {
      dog.animationName = animations.pop();
      dog.run();
    });

    requestAnimationFrame(this._dogsTracker);
  }

  async swapSymbols() {
    this._hideOverlay();
    this._wildSymbols.forEach(symbol => {
      SoundManager.play(eSounds.EST_WILD_SWAP.soundName, eSounds.EST_WILD_SWAP.volume, false, eSounds.EST_WILD_SWAP.group);
      const promise = symbol.startSwapAnimation();
      this._wildsSwapPromises.push(promise);
    });

    this._swapPoints.forEach(swapData => {
      this.ControllerReels.reels[swapData.reelIndex].symbols[Number(swapData.trackIndex) + this.ControllerReels.topSimbolsCount].visible = false;
    });

    await Promise.allSettled(this._wildsSwapPromises);

    this._wildSymbols.forEach(symbol => {
      symbol.visible = false;
      symbol.animationSymbol.resetAnimation();
    });

    this._swapPoints.forEach(swapData => {
      const symbol = this.ControllerReels.reels[swapData.reelIndex].symbols[Number(swapData.trackIndex) + this.ControllerReels.topSimbolsCount];
      symbol.changeSymbol(eSymbolIndex.ESI_WILD);
      symbol.visible = true;
    });

    GlobalDispatcher.dispatch(eEventTypes.EET_FEATURE__SYMBOLS_SWAP__COMPLETE);
  }

  _calculateDropPoints(data) {
    this._dropPoints = {};
    this._swapPoints = [];

    for (let i = 0; i < this.dogs.length; i++) {
      this._dropPoints[i] = [];
    }

    for (let [reelIndex, trackIndex] of data) {
      this._swapPoints.push({ trackIndex, reelIndex });
      const symbol = this.ControllerReels.reels[reelIndex].symbols[trackIndex + this.ControllerReels.topSimbolsCount];

      const posToDog = this.dogs[trackIndex].toLocal({
        x: 0,
        y: 0
      }, symbol);

      this._dropPoints[trackIndex].push({
        reelIndex,
        x: posToDog.x,
        y: this.dogs[trackIndex].y,
      });
    }
  }

  _dogsTracker = async () => {
    this._tryToDropWilds();

    if (this.dogs.every(dog => dog.finished)) {
      await Promise.allSettled(this._wildsAppearancePromises);
      this._wildsAppearancePromises = [];

      GlobalDispatcher.dispatch(eEventTypes.EET_FEATURE__DOGS_RUN__COMPLETE);
      return;
    }

    requestAnimationFrame(this._dogsTracker);
  };

  _tryToDropWilds() {
    for (let trackIndex = 0; trackIndex < this.dogs.length; trackIndex++) {
      if (!this._dropPoints[trackIndex].length) continue;

      for (let i = 0; i < this._dropPoints[trackIndex].length; i++) {
        const dropData = this._dropPoints[trackIndex][i];

        if (this.dogs[trackIndex].distanceCovered >= dropData.x) {
          const symbol = this._wildSymbolsStack.pop();
          symbol.x = this._symbolsPositionsGrid[trackIndex][dropData.reelIndex].x;
          symbol.y = this._symbolsPositionsGrid[trackIndex][dropData.reelIndex].y;
          symbol.visible = true;

          const promise = symbol.startWildAppearance();
          this._wildsAppearancePromises.push(promise);
          SoundManager.play(eSounds.EST_WILD_STOP.soundName, eSounds.EST_WILD_STOP.volume, false, eSounds.EST_WILD_STOP.group);

          this._dropPoints[trackIndex] = [...this._dropPoints[trackIndex].slice(0, i), ...this._dropPoints[trackIndex].slice(i + 1)];
          i--;
        }
      }
    }
  }

  _createContainer() {
    this.container = new PIXI.Container();
    this.reelsContainer.addChild(this.container);
  }

  _createDogs() {
    const tracksCount = this.ControllerReels.reels[0].symbols.length - this.ControllerReels.topSimbolsCount - this.ControllerReels.bottomSimbolsCount;
    for (let i = 0; i < tracksCount; i++) {
      const dog = new Dog();
      this.container.addChild(dog);
      const y = dog.toLocal({
        x: 0,
        y: 0
      }, this.ControllerReels.reels[0].symbols[i + this.ControllerReels.topSimbolsCount]).y;
      dog.startPosition = {
        x: -dog.width / 2,
        y,
      };
      dog.trackDistance = this.reelsContainer.mask.width + dog.width;
      this.dogs.push(dog);
    }
  }

  _createSymbols() {
    for (let i = 0; i < this._wildSymbolsCount; i++) {
      const symbol = FlashLib.createItemFromLibrary(eLibraryItems.ELI_SYMBOL, 'GameFlashLib');
      symbol.changeSymbol(eSymbolIndex.ESI_WILD);
      symbol.visible = false;
      this.container.addChild(symbol);
      this._wildSymbols.push(symbol);
    }
  }

  _createSymbolsPositionsGrid() {
    this._symbolsPositionsGrid = {};
    for (let i = 0; i < this.dogs.length; i++) {
      this._symbolsPositionsGrid[i] = [];

      this.ControllerReels.reels.forEach(reel => this._symbolsPositionsGrid[i].push(this.container.toLocal({
        x: 0,
        y: 0
      }, reel.symbols[i + this.ControllerReels.topSimbolsCount])));
    }
  }

  _createOverlay() {
    this.overlay = new PIXI.Graphics();
    this.overlay.beginFill(this._overlayColor, this._overlayAlpha);
    this.overlay.drawRect(0, 0, this.reelsContainer.width, this.reelsContainer.height);
    this.overlay.endFill();

    this.overlay.visible = false;
    this.container.addChild(this.overlay);
  }

  _showOverlay() {
    this.overlay.visible = true;
    gsap.fromTo(this.overlay, { alpha: 0 }, { alpha: 1, duration: 0.3 });
  }

  _hideOverlay() {
    gsap.fromTo(this.overlay, { alpha: 1 }, {
      alpha: 0, duration: 0.3, onComplete: () => {
        this.overlay.visible = false
      }
    });
  }

  get dogs() {
    return this._dogs;
  }
}
