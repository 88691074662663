import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';

export default class PaytableContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    this.addListeners();
    this.hide();
    this.event = true;
  }

  init() {
    this.wrapper = this.getChildByName('wrapper');
    this.content = this.wrapper.getChildByName('content');
    this.controls = this.wrapper.getChildByName('controls');
    this.pagesCount = this.content.layersData[0].frameCount;

    let range = [];
    for (let i = 1; i <= this.pagesCount; i++) {
      range.push(i);
    }

    this.controls.setRange(range);
    this.content.changeFrame({ params: this.controls.currentPage });
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE__CLICK, this.show.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE__BACK_CLICK, this.hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_SPIN_CLICK, this.hide.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize (data) {
    if (data.isMobile && data.isPortrait) {
      this.wrapper.scale.set(0.685);
      this.wrapper.x = 368;
      this.wrapper.y = 95;
    } else {
      this.wrapper.scale.set(1);
      this.wrapper.x = this.wrapper.displayData.x;
      this.wrapper.y = this.wrapper.displayData.y;
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
