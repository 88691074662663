import BaseSlotSymbol from 'Engine/containers/BaseSlotSymbol';
import { goldFontStyle } from '../../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class Symbol extends BaseSlotSymbol {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.animationSymbol = this.getChildByName('animationContainer');
    this.animation = null;
    this.incativeTintColor = 0x444444;
  }

  set onAnimationComplete(value) {
    this.animationSymbol.onComplete = value
  }

  init() {
    this.animationSymbol = this.getChildByName('animationContainer');
    this.valueTextField = this.getChildByName('value');
    this.valueTextField.visible = false;
    this.valueTextField.style = { ...this.valueTextField.style, ...goldFontStyle }
    super.init();
  }

  createTweens() {

  }

  changeSymbol(id, reel) {
    super.changeSymbol(id, reel);
    this.stopAnimation();
    if (this.animationSymbol) {
      this.animationSymbol.id = id;
    }
    this.valueTextField.visible = false;
  }

  startWildAppearance() {
    this.image = this.getChildAt(0);
    this.image.visible = false;
    return this.animationSymbol.startWildAppearance();
  }

  startSwapAnimation() {
    return this.animationSymbol.startSwapAnimation();
  }

  startAnimation() {
    this.image = this.getChildAt(0);
    this.animationSymbol.start(false);
    this.image.visible = false;
  }

  startStopAnimation() {
    this.image = this.getChildAt(0);
    this.image.visible = false;
    this.animationSymbol.startStopAnimation();
  }

  stopAnimation() {
    this.image.visible = true;
    if (this.animationSymbol) this.animationSymbol.stop();
  }


  setBlur(value) {
    // super.setBlur(value);
    // this.frame.setBlur();
    /*if (value && this.id < 6) {
       this.setActive(EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol);
    } else {
        this.setActive(true);
    }*/
    this.setActive(true);
  }

  setActive(value) {
    this.image.tint = value ? 16777215 : this.incativeTintColor;
  }

  setValue(value) {
    this.value = value;
    const formattedValue = MoneyFormat.format(this.value, false);
    const containerWidth = 278;
    const ratioWidthToHightFont = 0.75;
    const defaultSize = this.valueTextField.displayData.textRuns[0].textAttrs.size;
    this.valueTextField.style.fontSize = Math.min(defaultSize, Math.round(containerWidth / (ratioWidthToHightFont * (formattedValue.length))));
    this.valueTextField.y = this.valueTextField.displayData.y + (defaultSize - this.valueTextField.style.fontSize) / 2;
    this.valueTextField.visible = true;
    this.valueTextField.text = formattedValue;
  }

  addValue(value) {
    if (this.valueTextField.visible && this.value) {
      this.value += value;
    } else {
      this.value = value;
    }
    this.setValue(this.value);
  }

  goToFrame(frameIndex) {
    super.goToFrame(frameIndex);
    this.animationSymbol = this.getChildByName('animationContainer');
  }
}
