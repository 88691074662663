import { orangeGradientFontStyle, orangeGradientWithStrokeAndShadow } from '../../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../../enums/eSounds';
import eEventTypes from '../../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import { gsap } from 'gsap/all';
import eAnimationNames from '../../../enums/eAnimationNames';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../../enums/eAnimationTypes';

export default class ControllerFreespinsStart extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init();
    this.initAnimation();
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_FREESPIN_IN_STATE__START, this.updateFreeSpinsAmount.bind(this));
  }

  init() {
    super.init();
    this.title = this.wrapper.getChildByName('title');
    this.titleText = this.title.getChildByName('freespins_popup_start_you_win');
    this.amount = this.wrapper.getChildByName('amount');
    this.freespinsText = this.wrapper.getChildByName('freespins_popup_start_freespin');

    this.title.centring();
    this.titleText.correctPosition('center', 'center');

    this.setTextStile();
    this.createTitleAnimationTimeline();
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_FREE_SPINS_POPUPS);
    this.animation.visible = false;
    this.wrapper.getChildByName('animationPlace').addChild(this.animation);
  }

  setTextStile() {
    this.titleText.style = { ...this.titleText.style, ...orangeGradientWithStrokeAndShadow };
    this.amount.style = { ...this.amount.style, ...orangeGradientFontStyle };
    this.freespinsText.style = { ...this.freespinsText.style, ...orangeGradientFontStyle }
  }

  updateFreeSpinsAmount() {
    this.amount.text = EntryPoint.GameModel.freespins;
  }

  show(delay) {
    super.show(delay);
  }

  playAnimation() {
    this.animation.state.setAnimation(0, eAnimationNames.EAN_FREE_SPINS_START__START, false);
    this.animation.state.addAnimation(0, eAnimationNames.EAN_FREE_SPINS_START__IDLE, true, 0);
    this.animation.visible = true;
  }

  stopAnimation() {
    this.animation.visible = false;
    this.animation.state.clearTrack(0);
    this.animation.lastTime = null;
  }

  onShowStart() {
    super.onShowStart();
    this.playAnimation();
    this.startTitleAppearanceAnimation();
  }

  onShow() {
    super.onShow();
    setTimeout(() => this.hide(), 5000);
    SoundManager.play(eSounds.EST_START_FREESPINS.soundName, eSounds.EST_START_FREESPINS.volume, false, eSounds.EST_START_FREESPINS.group);
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_START__SHOWN_UP);
  }

  onHide() {
    super.onHide();
    this.stopAnimation();
    const freespinsSound = SoundManager.getSound(eSounds.EST_START_FREESPINS.soundName);
    const tween = gsap.timeline();
    tween
      .to(freespinsSound, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.pause(eSounds.EST_START_FREESPINS.soundName)
      });
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_START__ON_COMPLETE)
  }
}
