import eAnimationNames from './eAnimationNames';

export default {
  EBPT_FREE_SPINS: {
    type: 'freespins',
    animations: {
      idle: eAnimationNames.EAN_PURCHASE__CARD_FREESPINS__IDLE,
      action: eAnimationNames.EAN_PURCHASE__CARD_FREESPINS__ACTION,
    }
  }
};
