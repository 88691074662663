import BaseOverlayContainer from 'Engine/containers/overlay/BaseOverlayContainer';

export default class ControllerOverlay extends BaseOverlayContainer {
  constructor(data, displayData) {
    super(data, displayData);

    this._alphaValue = 0.78;
    this.changeColor('0x000639');
  }
}
