import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import eEventTypes from '../enums/eEventTypes';

export default class SymbolsSwapFeatureState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_FEATURE__SYMBOLS_SWAP__COMPLETE, this.onComplete, this)
    }

    onComplete() {
        if(this.active) {
            this.complete();
        }
    }

}
