import winController from './winController';
import gsap from 'gsap';

export default class BigWinController extends winController {
  constructor(props) {
    super(props);
  }

  _playSound() {
    const sound = super._playSound();
    gsap.fromTo(sound, { volume: 0 }, { volume: this._config.sound.volume, duration: 0.8 });
  }
}
