import GameModel from '../model/GameModel';
import InitState from 'Engine/states/InitState';
import IdleState from 'Engine/states/IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowWinningsState from './ShowWinningsState';
import ShowWinState from './ShowWinState';
import ShowLinesWinState from './ShowLinesWinState';
import MinimalRollingState from './MinimalRollingState';
import EntryPoint from 'Engine/EntryPoint';
import ShowStartScreenState from './showStartScreenState';
import StopWinState from './StopWinState';
import FreespinsOutState from './FreespinsOutState';
import FreespinsInState from './FreespinsInState';
import eStateTypes from '../enums/eStateTypes';
import SymbolsSwapFeaturesState from './SymbolsSwapFeatureState';
import DogsRunFeaturesState from './DogsRunFeatureState';
import BuyBonusState from './BuyBonusState';
import WaitUserInteractionState from './WaitUserInteractionState';

export const StatesConfig = [
  {
    name: eStateTypes.EST_INIT, class: InitState, transitions: [
      {
        to: eStateTypes.EST_START_SCREEN, condition: function () {
          return EntryPoint.GameSettings.introScreen;
        }
      },
      {
        to: eStateTypes.EST_WAIT_USER_INTERACTION, condition: function () {
          return EntryPoint.GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ], entry: true
  },
  {
    name: eStateTypes.EST_IDLE, class: IdleState, transitions: [
      {
        to: eStateTypes.EST_REQUEST_ROLLING, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_REQUEST_ROLLING, class: RequestRollingState, transitions: [
      {
        to: eStateTypes.EST_MINIMAL_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_MINIMAL_ROLLING, class: MinimalRollingState, transitions: [
      {
        to: eStateTypes.EST_FEATURES__DOGS_RUN, condition: function () {
          return EntryPoint.GameModel.isWildsFeature;
        }
      },
      {
        to: eStateTypes.EST_STOP_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_STOP_ROLLING, class: StopRollingState, transitions: [
      {
        to: eStateTypes.EST_FEATURES__SYMBOLS_SWAP, condition: function () {
          return EntryPoint.GameModel.isWildsFeature;
        }
      },
      {
        to: eStateTypes.EST_SHOW_LINES_WIN, condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {
          return EntryPoint.GameModel.freespinsEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return EntryPoint.GameModel.freespinsTriggered ;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_LINES_WIN, class: ShowLinesWinState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WINNINGS, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WIN, class: ShowWinState, transitions: [
      {
        to: eStateTypes.EST_STOP_WIN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_STOP_WIN, class: StopWinState, transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {

          return EntryPoint.GameModel.freespinsEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return EntryPoint.GameModel.freespinsTriggered ;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WINNINGS, class: ShowWinningsState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WIN, condition: function () {
          return GameModel.totalWin;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {

          return EntryPoint.GameModel.freespinsEnabled && EntryPoint.GameModel.freespins <= 0;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return EntryPoint.GameModel.freespinsTriggered;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },

  {
    name: eStateTypes.EST_START_SCREEN, class: ShowStartScreenState, transitions: [
      {
        to: eStateTypes.EST_WAIT_USER_INTERACTION, condition: function () {
          return EntryPoint.GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FREE_SPINS_IN, class: FreespinsInState, transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FREE_SPINS_OUT, class: FreespinsOutState, transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FEATURES__DOGS_RUN, class: DogsRunFeaturesState, transitions: [
      {
        to: eStateTypes.EST_STOP_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FEATURES__SYMBOLS_SWAP, class: SymbolsSwapFeaturesState, transitions: [
      {
        to: eStateTypes.EST_SHOW_LINES_WIN, condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {

          return EntryPoint.GameModel.freespinsEnabled && EntryPoint.GameModel.freespins <= 0

        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return EntryPoint.GameModel.freespinsTriggered;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_BUY_BONUS,
    class: BuyBonusState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return EntryPoint.GameModel.freespinsTriggered;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_WAIT_USER_INTERACTION,
    class: WaitUserInteractionState,
    transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      },
    ]
  },
];
