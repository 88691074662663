import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';
import eEventTypes from '../enums/eEventTypes';

export default class FreespinsOutState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_END__ON_COMPLETE, this.onCounterComplete, this)
  }

  onCounterComplete() {
    if(this.active) {
      this.complete();
    }
  }
  start() {
    super.start();
    OPWrapperService.infoPanel.setGameState(eGameStateTypes.EGST_REGULAR);
    EntryPoint.GameModel.freespinsEnabled = false;
    EntryPoint.AutoPlaySettings.active = false;
  }
}
