import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import SoundManager from 'Engine/soundManager/SoundManager';
import WinAmountAnimated from '../../../winAmountAnimated/winAmountAnimated';
import animationCreator from 'Engine/animations/animationCreator';
import SmallWinController from './winControllers/smallWinController';
import BigWinController from './winControllers/bigWinController';
import WinController from './winControllers/winController';
import { gsap } from 'gsap';
import eAnimationTypes from '../../../enums/eAnimationTypes';
import { eSounds } from '../../../enums/eSounds';
import eBigWinTypes from '../../../enums/eBigWinTypes';
import eEventTypes from '../../../enums/eEventTypes';
import { eBigWinAnimationNames, eEpicWinAnimationNames, eMegaWinAnimationNames } from '../../../enums/eAnimationNames';
import EventEmitter from '../../eventEmitter/eventEmitter';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.eventMode = 'static';
    this.winControllers = {};
    this.stack = [];
    this.cancelExecutionCallbacks = [];
    this.currentStep = null;
    this._completeDelay = 1500;

    Object.assign(this, new EventEmitter());

    this.stepsData = {
      [eBigWinTypes.EBWT_WIN]: {
        delayed: true,
        controller: SmallWinController,
        config: { animationName: null, sound: eSounds.EST_WIN, duration: 1000, counterOffset: 400 }
      },
      [eBigWinTypes.EBWT_BIG_WIN]: {
        delayed: true,
        controller: BigWinController,
        config: {
          animationName: eBigWinAnimationNames,
          sound: eSounds.EST_BIG_WIN,
          duration: 4000,
          counterOffset: 1500,
        }
      },
      [eBigWinTypes.EBWT_MEGA_WIN]: {
        delayed: true,
        controller: WinController,
        config: {
          animationName: eMegaWinAnimationNames,
          sound: eSounds.EST_MEGA_WIN,
          duration: 4000,
          counterOffset: 1500,
        }
      },
      [eBigWinTypes.EBWT_EPIC_WIN]: {
        delayed: true,
        controller: WinController,
        config: {
          animationName: eEpicWinAnimationNames,
          sound: eSounds.EST_EPIC_WIN,
          duration: 4000,
          counterOffset: 1000,
        }
      },
    };

    this._init();
    this._initAnimationPromise = this._initAnimation();
    this._initWinControllers();
    this._addListeners();
  }

  async show() {
    this.visible = true;
    this.winContainer.visible = true;
    this.eventMode = 'static';
    this.alpha = 1;

    // this.stopMainBackgroundSound();
    // this.startWinBackground();

    this._createStack();
    await this.checkInitializationWinControllers();
    this._execute();
  }

  async checkInitializationWinControllers() {
    const promises = [];
    this.stack.forEach(controller => {
      if (controller.promise && !controller.promise.isFulfilled) promises.push(controller.promise);
    });
    if (promises.length) {
      window.OPPreloader.showDelayedPreloader();
      await Promise.all(promises);
      window.OPPreloader.hideDelayedPreloader();
    }
  }

  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    gsap.to(backgroundMainGameSound, { volume: 0, duration: 0.5 })
  }

  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    gsap.fromTo(backgroundMainGameSound, { volume: 0 }, { volume: eSounds.EST_BACKGROUND.volume, duration: 1.5 })
  }

  startWinBackground() {
    SoundManager.play(eSounds.EST_BIG_WINS_BACKGROUND.soundName, eSounds.EST_BIG_WINS_BACKGROUND.volume, true, eSounds.EST_BIG_WINS_BACKGROUND.group);
  }

  stopWinBackground() {
    SoundManager.stop(eSounds.EST_BIG_WINS_BACKGROUND.soundName);
  }

  showOverlay = (duration) => {
    this.overlay.show(duration / 1000);
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
    // gsap.killTweensOf(this.panel);
    // gsap.to(this.panel, {alpha: 0, duration});
  };

  _init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.wrapper = this.getChildByName('wrapper');
    // this.panel = this.wrapper.getChildByName('panel');
    this.winContainer = this.wrapper.getChildByName('smallPanel');
    this.winContainer.visible = false;
    this.winAmount = this.winContainer.getChildByName('amount');

    this.counter = new WinAmountAnimated(this.winAmount, { fontSize: 120 });
    this.counter.decimals = EntryPoint.GameModel.currencyInfo.decimals;

    this._hideOverlay();
  }

  async _initAnimation() {
    this.animation = await animationCreator.createAnimationWithDelayedLoading(eAnimationTypes.EAT_BIG_WIN);

    console.log(this.animation, 'this.animation');

    this.animation.visible = false;
    // this.animation.state.addListener(this.animationListener);
    this.wrapper.getChildByName('animationContainer').addChild(this.animation);
  }

  async _initWinControllers() {
    try {
      [this.stepsData].forEach(steps => {
        for (let key in steps) {
          try {
            if (this.winControllers.hasOwnProperty(key)) continue;

            this.winControllers[key] = new steps[key].controller({
              controllerPopup: this,
              config: steps[key].config,
              animation: this.animation,
              counter: this.counter
            });

            if (steps[key].delayed) {
              this.winControllers[key].promise = new Promise(async (resolve, reject) => {
                try {
                  await this._initAnimationPromise;
                  this.winControllers[key].animation = this.animation;
                  this.winControllers[key].promise.isFulfilled = true;
                  resolve();
                } catch (error) {
                  console.error(`Error initializing animation for controller ${key}:`, error);
                  reject(error);
                }
              });
            }
          } catch (error) {
            console.error(`Error initializing controller for key ${key}:`, error);
          }
        }
      });
    } catch (error) {
      console.error('Error in _initWinControllers:', error);
    }
  }

  _addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_SHOW_WIN_STATE__START, this.show, this);
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__START, this._stop, this);
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__END, this._hide, this);
    this.on('pointertap', () => {
      StatesManager.goToNextState();
    });
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onGameResized,
      this
    );
  }

  _onGameResized(data) {
    if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      this.wrapper.y = this.displayData.height / 2 - 630 - 150;
      this.wrapper.x = this.displayData.width / 2;
      this.wrapper.scale.set(0.6);
    } else {
      this.wrapper.anchorDefault();
      this.wrapper.scale.set(this.wrapper.displayData.scaleX)
    }
  }

  _createStack() {
    let stackSource = Object.keys(this.stepsData);

    for (let key of stackSource) {
      if (EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet > EntryPoint.GameModel.winRankCoef[key]) {
        const controller = this.winControllers[key];
        const cancelExecutionCallback = controller.once('complete', this._execute);
        this.cancelExecutionCallbacks.push(cancelExecutionCallback);

        controller.startValue = EntryPoint.GameModel.winRankCoef[key] * EntryPoint.GameModel.bet;
        controller.endValue = EntryPoint.GameModel.totalWin;

        if (this.stack.length) this.stack[this.stack.length - 1].endValue = controller.startValue;

        this.stack.push(controller);
      }
    }
  }

  _execute = () => {
    if (!this.stack.length) {
      this._complete();
      return;
    }

    this.currentStep = this.stack.shift();
    this.currentStep.start();
  };

  _complete() {
    GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP);
  }

  _stop() {
    this.counter.stop();
    this.counter.set(EntryPoint.GameModel.totalWin.toFixed(EntryPoint.GameModel.currencyInfo.decimals));

    this._cancelScheduledExecutions();
    this.currentStep.stop();

    if (this.stack.length) {
      this.stack.forEach(controller => controller.onEnd());

      this.currentStep = this.stack[this.stack.length - 1];
      this.currentStep.setIdle();
      this.stack = [];
    }

    this._waitCompleteDelay();
  }

  _cancelScheduledExecutions() {
    this.cancelExecutionCallbacks.forEach(cancelExecution => cancelExecution());
    this.cancelExecutionCallbacks = [];
  }

  _waitCompleteDelay = (timestamp = 0) => {
    if (!this._startWaitPoint) this._startWaitPoint = timestamp;
    if (timestamp - this._startWaitPoint >= this._completeDelay) {
      this._startWaitPoint = null;
      GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED);
      return;
    }

    requestAnimationFrame(this._waitCompleteDelay);
  };

  _hide() {
    this.eventMode = 'auto';
    // this.stopWinBackground();
    // this.playMainBackgroundSound();
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
    gsap.to(this, {
      alpha: 0, duration: 0.4, onComplete: () => {
        this.visible = false;
        if (this.animation) this.animation.visible = false;
        this.winContainer.visible = false;
        this._hideOverlay();
        this.emit('complete');
      }
    })
  }

  _hideOverlay() {
    this.overlay.hide();
    // gsap.killTweensOf(this.panel);
    // this.panel.alpha = 1;
  }
}

