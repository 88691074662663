import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import ServerManager from '../server/ServerManager';

export default class BuyBonusState extends BaseState {

  async start() {
    super.start();
    OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bonusPurchaseData.price;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    GlobalDispatcher.dispatch(eEventTypes.EET_BUY_BONUS);
    await ServerManager.onBuyBonus().then(this.complete.bind(this));
  }

  complete() {
    if (this.active) {
      EntryPoint.GameModel.bonusPurchaseData = null;
      super.complete();
    }
  }
}
