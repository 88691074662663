import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import eAnimationTypes from '../../enums/eAnimationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import { eSymbolsAnimationNames } from '../../enums/eAnimationNames';

export default class SymbolAnimation extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.onComplete = () => {
    };

    this.animationListener = {
      complete: (data) => {
        this._complete(data)
      }
    };

    this.animation = null;
    this._id = null;
    this._suffix = 'small';
    this._animationName = null;

    this.init();
    this.addListeners();
  }

  init() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_SYMBOLS);
    this.animation.visible = false;
    this.animation.state.addListener(this.animationListener);
    this.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add('stateStart:jackpotGameInState', () => this.isActive = this.animation.visible, this); //temp
    GlobalDispatcher.add('stateStart:jackpotGameOutState', () => this.animation.visible = this.isActive, this); //temp
  }

  start(loop) {
    this._playSpineAnimation(loop);
  }

  startWildAppearance() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eSymbolsAnimationNames.ESAN_WILD_SWAP, false);
    this.animation.state.addAnimation(0, this._animationName, true, 0);
    return new Promise((resolve) => this.resolve = resolve);
  }

  startSwapAnimation() {
    this.animation.state.setAnimation(0, eSymbolsAnimationNames.ESAN_WILD_SWAP, false);
    return new Promise((resolve) => this.resolve = resolve);
  }

  _playSpineAnimation(loop) {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, this._animationName, loop);
  }

  startStopAnimation() {
    // this.animation.visible = true;
    // this.animation.skeleton.setToSetupPose();
    // this.animation.state.setAnimation(0, this._animationName, false);
  }

  resetAnimation() {
    this.animation.state.clearTrack(0);
    this.animation.lastTime = null;
    this.animation.skeleton.setToSetupPose();
  }

  _complete(data) {
    if (data.animation.name === eSymbolsAnimationNames.ESAN_WILD_SWAP && this.resolve) {
      this.resolve();
      this.resolve = null;
    }

    this.onComplete(data);
  }

  stop() {
    if (this.animation) {
      this.animation.visible = false
    }
  }

  _updateAnimationName() {
    this._animationName = `${eSymbolsAnimationNames[this._id]}_${this._suffix}`
  }

  set suffix(value) {
    this._suffix = value;
    this._updateAnimationName();
  }

  set id(value) {
    this._id = value;
    this._updateAnimationName();
  }
}
