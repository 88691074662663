import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import { cloneArray, shuffle } from 'Engine/utils/array';
import eLibraryItems from '../../../enums/eLibraryItems';

export default class ControllerFreeSpinsGame {
  constructor(ControllerReels) {
    this.ControllerReels = ControllerReels;
    this.init();
  }

  init() {
    this.bonusGameReel = FlashLib.createItemFromLibrary(eLibraryItems.ELI_BIG_REEL, 'GameFlashLib');
    this.bonusGameReel.x = 750;
    this.bonusGameReel.y = -200;
    this.bonusGameReel.distanceTraveledFromStart = 0;
    this.bonusGameReel.distanceTraveledForStop = 0;
    this.bonusGameReel.distanceTraveledForBounce = 0;
    this.bonusGameReel._state = this.ControllerReels.eStates.ES_WAIT;
    this.bonusGameReel.symbols = [...this.bonusGameReel.children];
    const available = cloneArray(EntryPoint.configData.reels.availableFreespinsReels[1]);
    this.bonusGameReel.symbols.forEach(symbol => {
      symbol.changeSymbol(available[0]);
      symbol.animationSymbol.suffix = 'big';
      available.unshift(available.pop());
    });

    this.regularReels = this.ControllerReels.reels;
    this.freeSpinReels = [this.regularReels[0], this.bonusGameReel, this.regularReels[4]];
  }

  start() {
    this._swapReels(this.freeSpinReels);
    this.ControllerReels.setAvailableSymbols(EntryPoint.configData.reels.availableFreespinsReels);
  }

  stop() {
    this._swapReels(this.regularReels);
    this.ControllerReels.setAvailableSymbols(EntryPoint.configData.reels.availableReelsNormal);
  }

  _swapReels(reels) {
    this.ControllerReels.reels.forEach(reel => {
      reel.symbols.forEach(symbol => symbol.stopAnimation());
      this.ControllerReels.removeChild(reel);
    });

    this.ControllerReels.reels = reels;
    this.ControllerReels.reels.forEach((reel, index) => {
      reel.id = index;
      this.ControllerReels.addChild(reel);
    });

    this.ControllerReels.calculateSymbolsHeights();
  }
}
