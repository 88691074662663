import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import eEventTypes from '../enums/eEventTypes';

export default class ShowWinningsState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_WINNINGS_CONTROLLER__ALL_WINNINGS_COMPLETE, this.onAllWinningsComplete, this);
    }

    start() {
        super.start();
        // if ((!EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) && !EntryPoint.GameModel.isFreeSpinsMode && !EntryPoint.GameModel.isBigWin) {
        if (!EntryPoint.GameModel.winnings.scatters) this.complete();
        // }
    }

    onAllWinningsComplete() {
        if (this.active) {
            this.complete();
        }
    }
}
