import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { orangeGradientFontStyle } from '../../fontStyles';
import eEventTypes from '../../enums/eEventTypes';

export default class PaytableContent extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.currentFrame = 1;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
  }

  setMultipliers() {
    switch (this.currentFrame) {
      case 2:
        let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[2]];
        amounts = amounts.slice(2, amounts.length);

        for (let i = 0; i < 3; i++) {
          let element = this.children[1].getChildByName(`paytable_p2_coef_${i}`);
          if (element) {
            element.text = `${amounts[i]}`
          }
        }
        break;
      case 5:
        for (let i = 3; i <= 6; i++) {
          let element = this.children[1].getChildByName(`paytable_p5_coefs_${i - 3}`);
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            element.text = amounts.join('\n');
          }
        }
        break;
      case 6:
        for (let i = 7; i <= 10; i++) {
          let element = this.children[1].getChildByName(`paytable_p6_coefs_${i - 7}`);
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            element.text = amounts.join('\n');
          }
        }
        break;
    }
  }

  setTextStyles() {
    for (let i = 1; i <= 7; i++) {
      const label = this.children[1].getChildByName(`paytable_p${this.currentFrame}_label`);
      if (label) {
        label.style = { ...label.style, ...orangeGradientFontStyle, fontFamily: 'UbuntuRegular' }
      }
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params || 1;
    this.goToFrame(this.currentFrame);
    this.setMultipliers();
    this.setTextStyles();
  }

  destroy() {
    GlobalDispatcher.remove(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
    super.destroy();
  }
}
