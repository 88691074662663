import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import SoundManager from 'Engine/soundManager/SoundManager';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import eEventTypes from '../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerConfirm extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.data = null;

    this.init();
    this.addListeners();
  }

  init() {
    this.title = this.getChildByName('purchase_confirm_title');
    this.priceLabel = this.getChildByName('purchase_confirm_price_label');
    this.priceAmount = this.getChildByName('purchase_confirm_price_amount');
    this.btnCancel = this.getChildByName('btnCancel');
    this.btnContinue = this.getChildByName('btnContinue');
    this.btnCancel.enabled = true;
    this.btnContinue.enabled = true;
    makeHitArea(this.btnCancel);
    makeHitArea(this.btnContinue);
    this.eventMode = 'static';
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PURCHASE_CONFIRM, this.onDataReceived.bind(this));
    this.btnCancel.addListener('pointertap', this.hide.bind(this));
    this.btnContinue.addListener('pointertap', this.continuePurchase.bind(this));
  }

  onDataReceived(data) {
    this.data = data;
    this.price = data.params.price;
    this.priceAmount.text = MoneyFormat.format(this.price, true);
    this.show();
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  continuePurchase() {
    SoundManager.play('sounds/mainGame/ButtonClick');
    EntryPoint.GameModel.buyBonus(this.data);
  }
}
