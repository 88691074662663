import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap/all';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../../enums/eSounds';

export default class BasePopup extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.visible = false;

    this.addListeners();
  }

  init() {
    // this.bgAnimation = this.getChildByName('bgAnimation');
    this.background = this.getChildByName('background');
    this.wrapper = this.getChildByName('wrapper');
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      this.wrapper.scale.set(0.7);
      this.background.goToFrame(1);
    } else {
      this.wrapper.scale.set(this.wrapper.displayData.scaleX);
      this.wrapper.anchorDefault();
      this.background.goToFrame(2);
    }
  }

  show(delay) {
    setTimeout(() => {
      this.visible = true;
      this.onShowStart();
      gsap.to(this, { alpha: 1, duration: 0.6, onComplete: () => this.onShow() })
    }, delay);
    this.stopMainBackgroundSound();
    // this.bgAnimation.start();
  }

  hide() {
    gsap.to(this, {
      alpha: 0, duration: 0.3, onComplete: () => {
        this.visible = false;
        // this.bgAnimation.stop();
        this.onHide();
      }
    })
    this.playMainBackgroundSound();
  }

  onShowStart() {

  }

  onShow() {

  }

  onHide() {
    this.titleAnimationtimeline.pause('start');
  }

  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    const tween = gsap.timeline();
    tween
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.pause(eSounds.EST_BACKGROUND.soundName)
      })
  }

  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    const tween = gsap.timeline();
    tween
      .call(() => {
        SoundManager.play(eSounds.EST_BACKGROUND.soundName, eSounds.EST_BACKGROUND.volume, true, eSounds.EST_BACKGROUND.group);
      })
      .fromTo(backgroundMainGameSound, { volume: 0 }, { volume: eSounds.EST_BACKGROUND.volume, duration: 1.5 })
  }

  createTitleAnimationTimeline() {
   this.titleAnimationtimeline = gsap.timeline({ paused: true });
    this.titleAnimationtimeline.fromTo(this.title.scale, { x: 0, y: 0 }, {
      x: 1,
      y: 1,
      duration: 1,
      ease: 'power1',
    }, 'start')
      .to(this.title.scale, { x: 0.95, y: 0.95, duration: 1.2, ease: 'power1.inOut', yoyo: true, repeat: -1 }, 'idle');
  }

  startTitleAppearanceAnimation() {
   this.titleAnimationtimeline.play();
  }
}
