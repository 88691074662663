import BaseSettingsContainer from 'Engine/containers/setting/BaseSettingsContainer';
import { orangeGradientFontStyle } from '../../../fontStyles';

export default class ControllerSettings extends BaseSettingsContainer {
  init() {
    super.init();
    const titles = [this.wrapper.getChildByName('settings_sound_title'), this.wrapper.getChildByName('settings_game_title')];
    titles.forEach(title => title.style = { ...title.style, ...orangeGradientFontStyle });
  }
}
