import FlashLib from 'flashlib_onlyplay';
import ControllerFeatures from './features/ControllerFeatures';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
  }

  init() {
    const slotContainer = this.getChildByName('slotContainer');
    this.reelsContainer = slotContainer.getChildByName('reelsContainer');

    this.ControllerReelsBackground = slotContainer.getChildByName('reelsBackground');
    this.ControllerReels = this.reelsContainer.ControllerReels;

    new ControllerFeatures({
      controllerReels: this.ControllerReels,
      controllerReelsBackground: this.ControllerReelsBackground,
      reelsContainer: this.reelsContainer,
    });
  }
}
