import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
  parseInit(data) {
    let newData = {};

    newData.action = 'init';
    newData.balance = data.balance;
    newData.params = {
      betPossible: data.bets_available
    };
    newData.current = {
      bet: data.bet_amount,
    };
    newData.currencyInfo = {
      decimals: data.decimals,
      symbol: data.currency,
      currency: data.currency
    };
    newData.paytableInfo = data.slot_machine.paytable;
    newData.bonusCostMultiplier = data.bonus_buy_multiplier;
    newData.freespinsData = this._makeFreespinsData(data);
    newData.wilds = data.wilds;
    newData.freebets = data.freebets;

    return newData;
  }

  parseSpin(data) {
    let newData = {};

    newData.action = 'spin';
    newData.balance = data.balance;
    newData.spin = {
      reels: data.spin?.reels || data.bonus.reels,
      winnings: this._makeWinnings(data),
      bet: data.bet_amount,
    };
    newData.freespinsData = this._makeFreespinsData(data);
    newData.wilds = data.wilds;
    newData.freebets = data.freebets;

    return newData;
  }

  parsePurchaseBonusGame(data) {
    let newData = {};
    newData.action = 'buy';
    newData.balance = data.balance;
    newData.freespinsData = this._makeFreespinsData(data);

    newData.freebets = data.freebets

    return newData;
  }

  _makeWinnings(data) {
    const spinData = data.spin;
    const isFreespins = EntryPoint.GameModel.freespinsEnabled;
    let winnings = {};
    winnings.totalWin = 0;

    winnings.lines = [];
    if (spinData) {
      for (const lineNumber in spinData.win_lines) {
        const lineLeng = spinData.lines_icons[lineNumber];
        if (!isFreespins && lineLeng < 3) continue;
        if (isFreespins && lineLeng < 2) continue;
        let line = {};
        const payLines = isFreespins ? EntryPoint.configData.freeSpinsPayLines : EntryPoint.configData.payLines;
        line.path = payLines[lineNumber - 1].concat().splice(0, lineLeng);
        // line.line = this.linesRelation[winData.payline.number];
        line.line = lineNumber - 1;
        line.count = lineLeng;
        line.win = spinData.win_lines[lineNumber] * data.bet_amount / 20;

        winnings.lines.push(line);

        winnings.totalWin = spinData.total_win_money;
      }
    }

    return winnings;
  }

  _makeFreespinsData(data) {
    if (!data.freespins) {
      return null;
    }
    return {
      totalWin: data.freespins.freespins_total_win * data.bet_amount,
      freespins: data.freespins.freespins_count,
      freespinsTriggered: data.freespins.freespins_triggered,
      isFreespinsGame: data.freespins.is_freespins,
      freespinsCountGiven: data.freespins.freespins_won_count,
    };
  }
}
