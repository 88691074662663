import BaseCheckBox from 'Engine/base/ui/BaseCheckBox';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../enums/eSounds';

export default class BaseGameCheckBox extends BaseCheckBox {
  constructor(...arg) {
    super(...arg);
  }

  onClick() {
    this.checked = !this.checked;
    let actionData = this.actions[this.checked ? 'checked' : 'unchecked'];
    if (actionData) {
      if (actionData.callback) {
        SoundManager.play(eSounds.EST_BUTTON_CLICK.soundName, eSounds.EST_BUTTON_CLICK.volume, false, eSounds.EST_BUTTON_CLICK.group);
        actionData.callback.call(actionData.context, actionData.params);
      }
    }
  }
}
