import ErrorManager from 'Engine/ErrorManager';
import Utils from 'Engine/Utils';
import * as queryString from 'querystring';

export default class XXXData {
  constructor() {
    this.adress = window.location.origin;

    this._tryGetSessionForDev();

    this.sid = Utils.getUrlParam('sid');
    this.gid = Utils.getUrlParam('gid');

    this.cheatsParams = ['force_reels', 'skip_freespins', 'force_bonus', 'force_wilds'];
  }

  async sendInit() {
    const data = await this.sendMessage({}, `game_init`, () => {}, 'GET', 'game_init');
    await window.OPWrapperService.init({
      partnerConfig: data.partner_config,
      language: data.lang,
      tournaments: data.tournaments,
      currencyInfo: {
        currency: data.currency,
        decimals: data.decimals,
      },
    });
    return await this.sendMessage({}, 'config');
  }

  async sendMessage(data, action, callback = ()=>{}, method = 'POST', urlPath = 'game_request') {
    let url = this.adress + `${urlPath}?sid=${this.sid}&gid=${this.gid}`;
    data.id = action;
    data.ver = '1.0';

    this._trySendCheatParams(data);

    const config = {
      method
    };

    if (method === 'POST') {
      config.body = JSON.stringify(data)
    }

    return await fetch(url, config)
      .then((resp) => resp.json())
      .then(ErrorManager.parseResponseError)
      .then((data) => {
        callback(data);
        return data;
      })
      .catch(e => {
        if (e.name === 'OPWrapperError') {
          throw e;
        } else {
          console.error(e);
          window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
        }
      });
  }

  _trySendCheatParams(data) {
    for (const cheatsParam of this.cheatsParams) {
      if (window[cheatsParam]) {
        data[cheatsParam] = window[cheatsParam];
        delete window[cheatsParam];
      }
    }
  }

  _tryGetSessionForDev() {
    const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
    if (devEnvironments.some(regexp => regexp.test(this.adress))) {
      const queryParams = queryString.parse(window.location.search.substr(1));
      if (!Object.keys(queryParams).length) {
        this._getSession();
      }
      this.adress = `https://${Utils.getUrlParam('api')}/`
    } else if (/192.168/.test(this.adress)) {
      this.adress = ''
    } else {
      this.adress = location.protocol + '//' + Utils.getUrlParam('api') + '/';
    }
  }

  async _getSession() {
    try {
      const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=iggyracing&no_redirect&no_op_launcher', {
        method: 'GET',
      });
      const response = await raw.json();
      if (response.url) {
        const searchParams = response.url.split('?')[1];
        window.location = `${window.location.origin}?${searchParams}`;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
