import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import './style.css';
import { gsap } from 'gsap';
import eStateTypes from './enums/eStateTypes';
import eEventTypes from './enums/eEventTypes';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from './enums/eSounds';
import EntryPoint from 'Engine/EntryPoint';
import { uiConfig } from './controllers/ui/config';
import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';

export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.uiContainer = null;
    this._duraitionSymbolAnimation = 2000;

    this.addListeners();
  }

  onGameResized(data) {
    let halfGameWidth = this.backgroundContainer.displayData.width / 2;
    let halfGameHeight = this.backgroundContainer.displayData.height / 2;
    halfGameHeight -= data.isMobile ? 640 : 0;

    if (data.isMobile && data.isPortrait) {
      // this.slotContainer.scale.set(0.705);
      // this.slotContainer.x = 636;
      // this.slotContainer.y = 100;
      this.slotContainer.scale.set(0.685);
      this.slotContainer.x = 650;
      this.slotContainer.y = 114;
      const mainPivotX = halfGameWidth + 100;
      const mainPivotY = halfGameHeight - 50;
      this.pivot.x = mainPivotX;
      this.pivot.y = mainPivotY;
      this.x = mainPivotX;
      this.y = mainPivotY;
    } else {
      this.slotContainer.scale.set(1);
      this.slotContainer.x = this.slotContainer.displayData.x;
      this.slotContainer.y = this.slotContainer.displayData.y;
    }

    if (!data.isMobile) {
      const mainPivotX = halfGameWidth + 300;
      const mainPivotY = halfGameHeight;
      this.pivot.x = mainPivotX;
      this.pivot.y = mainPivotY;
      this.x = mainPivotX;
      this.y = mainPivotY;
    }
  }

  init() {
    this.displayData.x = this.x;
    this.displayData.y = this.y;
    this.winningsController = new BaseSlotWinningsController({ nextWinTimeout: this._duraitionSymbolAnimation });

    this.slotContainer = this.getChildByName('SlotWrapper');
    this.slot = this.slotContainer.getChildByName('slotContainer');
    this.reelsContainer = this.slot.getChildByName('reelsContainer');


    this.freespinsStart = this.getChildByName('freespinsStart');
    this.freespinsEnd = this.getChildByName('freespinsEnd');

    this.backgroundContainer = this.getChildByName('backgroundContainer');
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_MODEL__LINES_CHANGED, this.onLinesChanged, this);
    GlobalDispatcher.add(eEventTypes.EET_STATE_MANAGER__STATE_CHANGED, this.onStateChanged, this);
    GlobalDispatcher.add(eEventTypes.EET_INIT_STATE__END, this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
    GlobalDispatcher.add(eEventTypes.EBET_ANTISIPATOR__START, this.onStartAntisipator, this);
    GlobalDispatcher.add(eEventTypes.EBET_ANTISIPATOR__STOP, this.onStopAntisipator, this);

    // GlobalDispatcher.add('eEventTypes.EET_DATA_UPDATED', this.checkForFreeSpins, this);
  }

  onStartAntisipator() {
    gsap.killTweensOf(this.scale);
    gsap.to(this.scale, { x: 1.03, y: 1.03, duration: 2.4, delay: 0.3 })
  }

  onStopAntisipator() {
    gsap.killTweensOf(this.scale);
    gsap.to(this.scale, { x: 1, y: 1, duration: 0.2 })
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    if (!EntryPoint.GameSettings.introScreen) {
      SoundManager.play(eSounds.EST_BACKGROUND.soundName, eSounds.EST_BACKGROUND.volume, true, eSounds.EST_BACKGROUND.group);
      OPWrapperService.freeBetsController.show();
      const ControllerUi = window.OPWrapperService.initUi(uiConfig);
      new UiAdapter(ControllerUi).init({
        bonusLabelText: 'FREESPINS',
        clickSound: eSounds.EST_BUTTON_CLICK
      });
    }
  }

  onStateChanged(data) {
    switch (data.params) {
      case eStateTypes.EST_INIT:
        break;
      case eStateTypes.EST_IDLE:
        break;
      case eStateTypes.EST_FREE_SPINS_IN:
        this.freespinsStart.show(this.reelsContainer.willShowScattersAnimation ? this._duraitionSymbolAnimation : 0);
        this.winningsController.reset();
        break;
      case eStateTypes.EST_FREE_SPINS_OUT:
        this.freespinsEnd.show();
        this.winningsController.reset();
        break;
      case eStateTypes.EST_REQUEST_ROLLING:
        this.reelsContainer.start();
        this.winningsController.reset();
        // this.linesContainer.hideLines();
        // this.linesContainer.reset();
        // this.symbolsAnimationsContainer.removeAnimations();
        // this.lineWinningContainer.hide();
        break;
      case eStateTypes.EST_STOP_ROLLING:
        this.reelsContainer.stop(GameModel.reels);
        break;
      case eStateTypes.EST_SHOW_WIN:
        break;
      case eStateTypes.EST_SHOW_LINES_WIN:
        break;
      case eStateTypes.EST_SHOW_WINNINGS:
        this.winningsController.start();
        break;
    }
  }
}
