import WinController from './winController';
import gsap from 'gsap';

export default class SmallWinController extends WinController {
  constructor(props) {
    super(props);
    this._moveCounterDuration = 1;
    this._scaleCounterDuration = 3;
    this._moveCounterValue = 55;
    this._scaleCounterValue = 1.3;
  }

  start() {
    if (this.ControllerPopup.stack.length) {
      this.setDurationOnNextCall(4000);
      this.setCounterOffsetOnNextCall(0);
      this.ControllerPopup.stopMainBackgroundSound();
      this.ControllerPopup.startWinBackground();

      this.onEnd = () => {
        gsap.to(this.counter, { y: `+=${this._moveCounterValue}`, duration: this._moveCounterDuration });
        gsap.to(this.counter.scale, { x: this._scaleCounterValue, y: this._scaleCounterValue, duration: this._scaleCounterDuration });
        this.ControllerPopup.showOverlay(1000);
      };

      this.ControllerPopup.once('complete', () => {
        gsap.killTweensOf(this.counter);
        gsap.killTweensOf(this.counter.scale);
        this.counter.y = 0;
        this.counter.scale.set(1);
        this.ControllerPopup.stopWinBackground();
        this.ControllerPopup.playMainBackgroundSound();
      });

    } else {
      this.onEnd = () => {
      };
    }

    super.start();
  }
}
