import { orangeGradientFontStyle, orangeGradientWithStrokeAndShadow } from '../../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import { gsap } from 'gsap';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import BaseMoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../../enums/eSounds';
import eEventTypes from '../../../enums/eEventTypes';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../../enums/eAnimationTypes';
import eAnimationNames from '../../../enums/eAnimationNames';

export default class ControllerFreespinsEnd extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init();
    this.initAnimation();
  }

  init() {
    super.init();
    this.title = this.wrapper.getChildByName('title');
    this.titleText = this.title.getChildByName('freespins_popup_end_total_win');
    this.textFieldTotalWin = this.wrapper.getChildByName('amount');
    this.textFieldCurrency = this.wrapper.getChildByName('currency');

    this.title.centring();
    this.titleText.correctPosition('center', 'center');
    
    this.setTextStyle();
    this.createTitleAnimationTimeline();
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_FREE_SPINS_POPUPS);
    this.animation.visible = false;
    this.wrapper.getChildByName('animationPlace').addChild(this.animation);
  }

  setTextStyle() {
    this.titleText.style = { ...this.titleText.style, ...orangeGradientWithStrokeAndShadow };
    this.textFieldTotalWin.style = { ...this.textFieldTotalWin.style, ...orangeGradientFontStyle };
    this.textFieldCurrency.style = { ...this.textFieldCurrency.style, ...orangeGradientFontStyle };
  }

  show(delay) {
    super.show(delay);
    this.textFieldCurrency.text = EntryPoint.GameModel.currencyInfo.currency;
    const arrForDecimals = BaseMoneyFormat.formatCurrency(EntryPoint.GameModel.freespinsTotalWin, EntryPoint.GameModel.currencyInfo.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;

    this.startTitleAppearanceAnimation();
    this.playAnimation();

    gsap.fromTo(this, { totalWin: 0 }, {
      totalWin: EntryPoint.GameModel.freespinsTotalWin, duration: 2, onComplete: () => {
        setTimeout(() => {
          this.hide();
          this.textFieldTotalWin.text = BaseMoneyFormat.format(EntryPoint.GameModel.freespinsTotalWin, false);
        }, 2000)
      }
    });
    SoundManager.play(eSounds.EST_FS_TOTAL_WIN.soundName, eSounds.EST_FS_TOTAL_WIN.volume, false, eSounds.EST_FS_TOTAL_WIN.group);
  }

  playAnimation() {
    this.animation.state.setAnimation(0, eAnimationNames.EAN_FREE_SPINS_END__START, false);
    this.animation.state.addAnimation(0, eAnimationNames.EAN_FREE_SPINS_END__IDLE, true, 0);
    this.animation.visible = true;
  }

  stopAnimation() {
    this.animation.visible = false;
    this.animation.state.clearTrack(0);
    this.animation.lastTime = null;
  }

  onShow() {
    super.onShow();
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_END__SHOWN_UP);
  }

  onHide() {
    super.onHide();
    this.totalWin = 0;
    this.stopAnimation();
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_END__ON_COMPLETE)
  }

  set totalWin(value) {
    this._totalWin = value;
    this.textFieldTotalWin.text = BaseMoneyFormat.formatCurrency(parseFloat(value), this.decimalsForAnimation);
  }

  get totalWin() {
    return this._totalWin;
  }
}
